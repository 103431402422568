<template>
  <header id="header" class="fixed-top border-bottom bg-main shadow-sm">
    <div class="container-fluid shadow-sm">
      <nav class="navbar navbar-expand-md navbar-dark px-0 pr-sm-3">
        <router-link
          to="/jual/pesanan-group"
          class="btn p-0 mr-md-4 text-light h5 mb-0"
        >
          <i class="fas fa-arrow-left"></i>
        </router-link>
        <span
          class="
            navbar-brand
            bg-transparent
            rounded
            p-0
            text-center
            mr-0 mr-md-5
          "
        >
          <span class="h6 font-weight-bold text-truncate">Transaksi</span>
        </span>
        <div class="text-nowrap order-md-last">
          <a class="btn px-0 d-inline-block">
            <i class="fa fa-ellipsis-h text-main"></i>
          </a>
        </div>
      </nav>
    </div>
    <!-- .container -->
  </header>

  <div class="container-fluid mt-header">
    <div class="row" id="body-sidemenu">
      <!-- MAIN -->
      <div id="main-content" class="col with-fixed-sidebar">
        <div class="row mt-3 mb-2">
          <div class="col-12">
            <select class="form-control form-control-sm" v-model="state.status" @input="selectStatus">
              <option value="all">Semua...</option>
              <option value="pending">Pending</option>
              <option value="lunas">Lunas</option>
            </select>
          </div>
          <!-- .col -->
        </div>
        <!-- .row -->

        <!-- Filter mengikuti status transaksi -->
        <div class="row mb-2">
          <div class="col-12">
            <select class="form-control form-control-sm" id="sel1" required>
              <option value="">Semua Waktu Transaksi...</option>
              <option value="1">Sepekan Terakhir</option>
              <option value="2">Sebulan Terakhir</option>
              <option value="3">Setahun Terakhir</option>
            </select>
          </div>
          <!-- .col -->
        </div>
        <!-- .row -->

        <!-- Hanya muncul bila status transaksi = pending-->
        <div class="row mb-2">
          <div class="col-12">
            <select class="form-control form-control-sm" @input="selectGroup">
              <option value="">Pilih PO Grup Aktif...</option>
              <option v-for="po in state.poGroups" :key="po.id" :value="po.id">{{ po.name }}</option>
            </select>
          </div>
          <!-- .col -->
        </div>
        <!-- .row -->

        <form @submit.prevent="searchData">
        <div class="input-group input-group-sm my-3">
            <div class="input-group mb-3">
              <input type="text" class="form-control" placeholder="Cari..."
              v-model="state.search" />
              <span class="input-group-append">
                <button type="submit" class="btn btn-outline-main"><i class="fa fa-search"></i
              ></button>
              </span>
            </div>
        </div>
        </form>

        <div id="resultList" class="row mt-3 mb-5" v-if="state.exist">
          <div
            class="col-12 col-md-6 col-lg-4 col-xl-3"
            v-for="dt in state.data"
            :key="dt.id"
          >
            <div class="card mb-2">
              <div class="card-body p-0">
                <div class="float-left w-25 text-center">
                  <i
                    class="fas fa-people-arrows mt-3"
                    style="font-size: 40px"
                  ></i>
                </div>
                <div class="float-right w-75 p-2">
                  <router-link
                    :to="'/jual/transaksi/info/'+dt.id"
                    class="
                      card-title
                      d-block
                      mb-1
                      mt-1
                      card-link
                      stretched-link
                      text-truncate
                    "
                    ><span class="font-weight-bold">{{ dt.pesanan.pelanggan.prefix }} {{ dt.pesanan.pelanggan.name }}</span>
                    <span class="text-body small ml-2">#{{ dt.invoice_no }}</span></router-link
                  >
                  <p class="card-text mb-1 text-truncate">
                    <span class="currency">IDR {{ sumTotal(dt.pesanan.detail) }}</span> - {{ dt.pesanan.po.name }}
                  </p>
                </div>
                <div class="clearfix"></div>
              </div>
            </div>
          </div>
          <!-- .col -->
        </div>
        <!-- .row -->
        <div class="row mt-3 mb-5" v-else>
          <div class="col-12 col-md-6 col-lg-4 col-xl-3">
            <h5 class="text-center mt-4">Data Kosong!</h5>
          </div>
        </div>
      </div>
      <!-- main-content -->
    </div>
    <!-- .row -->
  </div>
  <!-- .container -->
</template>
<script>
import { reactive, onMounted } from "vue";
import axios from "axios";

export default {
  setup() {
    const state = reactive({
      data: {},
      exist: false,
      poGroups: {},
      status: 'pending',
      search: '',

    });
    const loadData = async () => {
      await axios.get("/api/seller/transaksi").then((res) => {
        if (res.data.success) {
          state.data = res.data.data;
          if (state.data.length > 0) {
            state.exist = true;
          } else {
            state.exist = false;
          }
        }
      });
    };
    const loadPoGroup = async () => {
      await axios.get("/api/seller/getPoGroup").then((res) => {
        if (res.data.success) {
          state.poGroups = res.data.data;
        }
      });
    };
    onMounted(() => {
      loadData();
      loadPoGroup();
    });
    const sumTotal = (dt) => {
      let ret = 0;
      dt.map((item) => {
        ret += Number(item.total);
      })
      let cur = Math.ceil(Number(ret));
      let mo  = (cur).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
      let hasil = mo.split(".")[0];
      return hasil;
    }
    const selectStatus = (event) => {
      let status = event.target.value; 
      axios.get("/api/seller/transaksi?status="+status).then((res) => {
        if (res.data.success) {
          state.data = res.data.data;
          if (state.data.length > 0) {
            state.exist = true;
          } else {
            state.exist = false;
          }
        }
      });
    }
    const searchData = () => {
      if(state.search){
        axios.get('/api/seller/transaksi/search?search='+state.search).then((res) => {
          if(res.data.success){
            if(res.data.data.length > 0){
              state.data = res.data.data;
              state.exist = true;
            }else{
              state.data = {};
              state.exist = false;
            }
          }
        })
      }
    }
    const selectGroup = (event) => {
        let group_id = event.target.value; 
        axios.get('/api/seller/transaksi/search?group_id='+group_id).then((res) => {
          if(res.data.success){
            if(res.data.data.length > 0){
              state.data = res.data.data;
              state.exist = true;
            }else{
              state.data = {};
              state.exist = false;
            }
          }
        })
    }
    return {
      state,
      sumTotal,
      selectStatus,
      searchData,
      selectGroup
    };
  },
};
</script>